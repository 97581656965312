.suggestions-container {
  position: absolute;
  background-color: white;
  box-shadow: 0px 5px 20px 0px rgb(69 67 96 / 10%);
  border-radius: 10px;
}
input {
  // border: 1px solid #999;
  padding: 6px 12px;
  border-radius: 20px;
  border: 0px;
  background: #eeeded;
  // width: 300px;
}
.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
  background-color: white;
}
.suggestions {
  // border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  // max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(200px + 1rem);
  border-radius: 10px;
}
.suggestions li {
  padding: 10px;
}
.suggestion-active,
.suggestions li:hover {
  background-color: #e6e6f0;
  // color: #fae042;
  cursor: pointer;
  // font-weight: 700;
}
// .suggestions li:not(:last-of-type) {
//   border-bottom: 1px solid #999;
// }
