.scrollTop {
  position: fixed;
  width: 40px !important;
  bottom: 20px;
  right: 20px;
  align-items: center;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
