.magpi-watermark {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 50%;
  width: 125px;
  animation-name: appear;
  animation-duration: 2s;
  animation-delay: 1s;
  transform: scale(0, 0);
  animation-fill-mode: forwards;
  transform-origin: 100% 50%;

  -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.1));
}

@keyframes appear {
  from {
    transform: scale(0, 0) rotate(30deg) translateX(50%);
  }
  to {
    transform: scale(1, 1) rotate(-1deg) translateX(50%);
  }
}

.section {
  margin-top: 100px;
}

h1.logo {
  font-size: 100px;
  font-weight: 100;
}

h1.logo .two {
  color: #999;
}

p {
  color: #666;
}

.payoff {
  color: #666;
}

.links {
  margin-bottom: -50px;
}

.links .btn {
  margin-bottom: 50px;
}

.btn-primary {
  color: #2b879e;
  border-color: #2b879e;
  background-color: white;
}
.btn-primary:hover {
  border-color: #2b879e;
  background-color: #2b879e;
}

.line {
  border-bottom: 3px solid #2b879e;
  width: 75px;
  display: inline-block;
}

.embed-responsive {
  border: 1px solid #999;
}

.footer {
  border-top: 1px solid #ddd;
  color: #999;
}
.footer .container {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  h1.logo {
    font-size: 50px;
    font-weight: 100;
  }
  .section {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .jumbotron {
    background-image: none;
  }
  .magpi-watermark {
    width: 60px;
    top: 40px;
  }
  .navbar-nav > li > a {
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-brand {
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
}
.nav-donate a {
  border-radius: 3px;
  border: 0;
  background-image: linear-gradient(100deg, rgb(0, 112, 186), rgb(0, 48, 135));
  color: white !important;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 40px !important;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .nav-donate a {
    border-radius: 0 !important;
    margin: 0 !important;
  }
}

.banner-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#carbonads {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

#carbonads {
  display: flex;
  max-width: 330px;
  background-color: hsl(0, 0%, 98%);
  box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.1);
}

#carbonads a {
  color: inherit;
  text-decoration: none;
}

#carbonads a:hover {
  color: inherit;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

#carbonads .carbon-wrap {
  display: flex;
}

.carbon-img {
  display: block;
  margin: 0;
  line-height: 1;
}

.carbon-img img {
  display: block;
}

.carbon-text {
  font-size: 13px;
  padding: 10px;
  line-height: 1.5;
  text-align: left;
}

.carbon-poweredby {
  display: block;
  padding: 8px 10px;
  background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 5px,
      hsla(0, 0%, 0%, 0.025) 5px,
      hsla(0, 0%, 0%, 0.025) 10px
    )
    hsla(203, 11%, 95%, 0.4);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 9px;
  line-height: 1;
}

.donate-spacer {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: center;
}
.donate-spacer > .spacer-body {
  text-transform: uppercase;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #aaa;
  font-size: 11px;
}

.donate-spacer > .spacer-line {
  flex: 1;
  border-top: 1px solid #ddd;
}

.crypto-list-item {
  display: flex;
  justify-items: center;
  font-size: 15px;
  border: 1px solid #ddd;
  background-color: #fcfcfc;
  padding: 10px;
  padding-bottom: 7px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #333;
  margin-bottom: 5px;
}
.crypto-list-item:hover {
  background-color: rgb(43, 135, 158);
  border-color: rgb(43, 135, 158);
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.crypto-list-item:hover .crypto-currency {
  color: white;
}
@media only screen and (max-width: 600px) {
  .crypto-currency {
    display: none;
  }
  .crypto-list-item {
    font-size: 11px;
  }
}
.crypto-address {
  flex: 1;
  text-align: right;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.crypto-currency {
  font-weight: bold;
  color: #999;
  margin-left: 1rem;
}
.social-icons {
  li {
    a {
      color: #6f6a6a;
      .insta:hover {
        background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
        color: transparent;
        background-clip: text;
      }
      .tweet:hover {
        color: #00acee;
      }
      .git:hover {
        color: #6e5494;
      }
      .link:hover {
        color: #2867b2;
      }
      .cube:hover {
        color: #9fef00;
      }
      .cloud:hover {
        color: #a6a6a6;
      }
      .rss:hover {
        color: #ee802f;
      }
    }
  }
}
